


































import { EGameSearchBy } from '@/api/schema';
import VMenuFilter from '@/components/VMenuFilter.vue';

interface IFilterData {
  search: string;
  searchBy: EGameSearchBy;
  providers: number[];
}
interface ISelectItem {
  text: string;
  value: EGameSearchBy;
}
export default {
  name: 'CopyStakeWhitelistFilter',

  components: {
    VMenuFilter
  },

  props: {
    providerOptions: {
      type: Array,
      default: (): any => []
    },
    search: {
      type: String,
      default: ''
    },
    searchBy: {
      type: String,
      default: ''
    },
    providers: {
      type: Array,
      default: (): number[] => []
    }
  },

  data(): any {
    return {
      localSearch: this.search,
      localSearchBy: this.searchBy,
      localProviders: this.providers
    };
  },
  computed: {
    searchByOptions(): ISelectItem[] {
      return [
        {
          text: 'Game Name',
          value: EGameSearchBy.NAME
        },
        {
          text: 'Game Code',
          value: EGameSearchBy.CODE
        }
      ];
    },

    currentSearchBy(): ISelectItem {
      return this.searchByOptions.find(
        (el: ISelectItem) => el.value === this.localSearchBy
      );
    },

    searchPlaceholder(): string {
      return `Search by ${this.currentSearchBy.text}`;
    }
  },
  watch: {
    search(newVal: string): void {
      this.localSearch = newVal;
    },
    searchBy(newVal: string): void {
      this.localSearchBy = newVal;
    },
    providers(newVal: number[]): void {
      this.localProviders = newVal;
    },

    localProviders(): void {
      this.$emit('update-filter', this.formatDataFilter(this.localSearch));
    }
  },
  methods: {
    formatDataFilter(search: string = null): IFilterData {
      return {
        search: search,
        searchBy: this.localSearchBy,
        providers: this.localProviders
      };
    },

    handleClickSearchButton(): void {
      this.$emit('update-filter', this.formatDataFilter(this.localSearch));
    },

    handleClickSearchClearButton(): void {
      this.$emit('update-filter', this.formatDataFilter());
    }
  }
};
