import { http } from '@/api/Connect';
import { BASE_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import {
  ICopyStakeGameItemResponse,
  ICopyStakeGamesParams,
  ICopyStakeGamesRemoveAllParams,
  ICopyStakeGamesResponse
} from '@/api/schema';

const API_GAME = (): string => `${BASE_OPERATOR()}/streamer/game`;
const API_GAME_PERMISSION = (): string => `${API_GAME()}/permission`;

export async function getCopyStakeGames(
  params: ICopyStakeGamesParams
): Promise<ICopyStakeGamesResponse> {
  return http
    .get(API_GAME(), {
      params,
      paramsSerializer: {
        indexes: null
      },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): ICopyStakeGamesResponse => data);
}

export async function updateCopyStakeGameImages(
  payload: FormData,
  id: string
): Promise<ICopyStakeGameItemResponse> {
  return http
    .put(`${API_GAME()}/${id}/images`, payload)
    .then(({ data }: AxiosResponse): ICopyStakeGameItemResponse => data);
}

export async function setPermissionGame(
  enabled: boolean,
  data: number[]
): Promise<string> {
  return http
    .patch(API_GAME_PERMISSION(), data, {
      params: {
        enabled
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}

export async function setTopStatusGames(
  top: boolean,
  data: number[]
): Promise<string> {
  return http
    .patch(`${API_GAME()}/top`, data, {
      params: {
        top
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}

export async function setPermissionGameDisabledAll(
  params: ICopyStakeGamesRemoveAllParams
): Promise<string> {
  return http
    .patch(`${API_GAME_PERMISSION()}/disable-all`, null, {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}
