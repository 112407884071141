import { render, staticRenderFns } from "./VMenuFilter.vue?vue&type=template&id=5f5a4b43&scoped=true&lang=pug"
import script from "./VMenuFilter.vue?vue&type=script&lang=ts"
export * from "./VMenuFilter.vue?vue&type=script&lang=ts"
import style0 from "./VMenuFilter.vue?vue&type=style&index=0&id=5f5a4b43&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5a4b43",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VCheckbox,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VMenu})
