









































































import { AxiosError } from 'axios';

import VMenuFilter from '@/components/VMenuFilter.vue';
import ImagePreviewDialog from '@/components/ImagePreviewDialog.vue';
import CopyStakeWhitelistFilter from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistFilter.vue';
import CopyStakeWhitelistAddGameModal from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistAddGameModal.vue';
import CopyStakeWhitelistTable from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistTable.vue';
import CopyStakeWhitelistConfirmModal from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistConfirmModal.vue';
import CopyStakeWhitelistGameDialog from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistGameDialog.vue';

import CopyStakeWhitelistMixin from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistMixin';

import {
  setPermissionGameDisabledAll,
  setTopStatusGames,
  updateCopyStakeGameImages
} from '@/api/CopyStake/Game';
import { ICopyStakeGameItemResponse } from '@/api/schema';

import { errorToastMessage } from '@/helpers/errorToastMessage';

interface CopyStakeWhitelistViewProps {
  editableGame: ICopyStakeGameItemResponse | null;
  openAddGameModal: boolean;
  openConfirmRemoveModal: boolean;
  openConfirmRemoveAllModal: boolean;
  editableGameImage: string;
  openEditGameImageDialog: boolean;
  openGameImagePreviewDialog: boolean;
}

export default {
  name: 'CopyStakeWhitelistView',

  components: {
    VMenuFilter,
    ImagePreviewDialog,
    CopyStakeWhitelistFilter,
    CopyStakeWhitelistTable,
    CopyStakeWhitelistConfirmModal,
    CopyStakeWhitelistAddGameModal,
    CopyStakeWhitelistGameDialog
  },

  mixins: [CopyStakeWhitelistMixin],

  data(): CopyStakeWhitelistViewProps {
    return {
      editableGame: null,
      openAddGameModal: false,
      openConfirmRemoveModal: false,
      openConfirmRemoveAllModal: false,
      editableGameImage: '',
      openEditGameImageDialog: false,
      openGameImagePreviewDialog: false
    };
  },

  computed: {
    isEditable(): boolean {
      return this.$role.can.update('copy_stake streamers');
    }
  },

  methods: {
    handleClickDeleteButton(): void {
      const countLabel = this.labelCountGames;

      this.setPermissionGames()
        .then(() => {
          this.resetSelectedItems();

          if (this.options.page !== 1) {
            this.resetCurrentPage();
          } else {
            this.updateGameList();
          }

          this.$toast.success(`${countLabel} were removed successfully`);
        })
        .catch((err: AxiosError) => errorToastMessage(err));
    },
    handleClickDeleteAllButton(): void {
      const { search, searchBy } = this.filters;

      setPermissionGameDisabledAll({
        [searchBy]: search.length ? search : null,
        providers: this.formatProviders
      })
        .then(() => {
          this.resetSelectedItems();
          this.updateGameList();
          this.$toast.success(`All games were removed successfully`);
        })
        .catch((error: AxiosError) => errorToastMessage(error));
    },
    handlerOpenEditGameImageDialog(
      payload: ICopyStakeGameItemResponse
    ): void {
      this.editableGame = { ...payload };
      this.openEditGameImageDialog = true;
    },
    handlerOpenGameImagePreviewDialog(imageUrl?: string): void {
      this.editableGameImage = imageUrl;
      this.openGameImagePreviewDialog = true;
    },
    handlerOnCloseGameDialog(): void {
      this.openEditGameImageDialog = false;
      this.editableGame = null;
    },
    handlerOnCloseGameImagePreviewDialog(): void {
      this.editableGame = null;
      this.openGameImagePreviewDialog = false;
    },
    async handlerChangeGamesTopStatus(
      games: ICopyStakeGameItemResponse[],
      newStatus: boolean
    ): Promise<void> {
      if (!games.length) return;

      try {
        this.loading = true;
        this.selectedItems = [];

        await setTopStatusGames(
          newStatus,
          games.map(({ id }) => id)
        );
      } catch (error) {
        errorToastMessage(error);
      } finally {
        await this.updateGameList();
        this.loading = false;
      }
    },
    async handlerOnSubmitForm({
      uploadImageThreeFourFile,
      uploadImageSixteenNineFile
    }: {
      uploadImageThreeFourFile?: Blob;
      uploadImageSixteenNineFile?: Blob;
    }): Promise<void> {
      try {
        this.loading = true;

        const { id } = this.editableGame;

        const formData = new FormData();

        if (uploadImageThreeFourFile) {
          formData.append('imageThreeFour', uploadImageThreeFourFile);
        }

        if (uploadImageSixteenNineFile) {
          formData.append('imageSixteenNine', uploadImageSixteenNineFile);
        }

        await updateCopyStakeGameImages(formData, id);
        await this.updateGameList();

        this.$toast.success('Game image was updated successfully');

        this.handlerOnCloseGameDialog();
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
