import { mapActions, mapGetters, mapState } from 'vuex';
import { getCopyStakeGames, setPermissionGame } from '@/api/CopyStake/Game';
import { toTitleCase } from '@/helpers/formatString';
import { pluralize } from '@/helpers/pluralize';

import {
  ICopyStakeGameItemResponse,
  IMenuFilterItem,
  GameProviderData,
  EGameSearchBy
} from '@/api/schema';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

const defaultFilter = {
  search: '',
  searchBy: EGameSearchBy.NAME,
  providers: []
};
export default {
  data(): any {
    return {
      options: { ...defaultOptions },
      filters: { ...defaultFilter },
      rows: [],
      topGamesCount: null,
      totalElements: null,
      whitelisted: true,
      selectedItems: [],
      loading: false
    };
  },

  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Onboarding', ['providers']),

    providersList(): IMenuFilterItem[] {
      return this.providers.map((provider: GameProviderData) => ({
        text: toTitleCase(provider.name),
        value: provider.name
      }));
    },

    formatProviders(): string[] {
      return this.filters.providers.map(({ value }) => value);
    },

    requestParams(): any {
      const { search, searchBy } = this.filters;

      return {
        [searchBy]: search,
        providers: this.formatProviders,
        permittedStates: this.whitelisted,
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sort: 'name',
        order: 'asc'
      };
    },

    pluralizeSelectLabels(): string[] {
      return ['game', 'games', 'games'];
    },

    labelCountGames(): string {
      const count = this.selectedItems.length;

      if (!count) return '';

      return `${count} ${pluralize(count, this.pluralizeSelectLabels)}`;
    },

    formattedSelectedGames(): number[] {
      return this.selectedItems.map(
        (game: ICopyStakeGameItemResponse) => game.id
      );
    }
  },

  methods: {
    ...mapActions('Onboarding', ['getProviders']),
    async updateGameList(): Promise<void> {
      this.loading = true;

      const response = await getCopyStakeGames(this.requestParams);

      this.rows = response.content;
      this.topGamesCount = response.topGamesCount;
      this.totalElements = response.totalElements;

      this.loading = false;
    },

    updateFilter(data: string[]): void {
      this.filters = { ...data };
    },

    resetFilter(): void {
      this.filters = { ...defaultFilter };
    },

    resetCurrentPage(): void {
      this.options.page = 1;
    },

    resetSelectedItems(): void {
      this.selectedItems = [];
    },

    setPermissionGames(): Promise<string> {
      return setPermissionGame(!this.whitelisted, this.formattedSelectedGames);
    }
  },

  watch: {
    operatorId: {
      handler(id: number): void {
        if (id) this.getProviders();
      },
      immediate: true
    },
    filters: 'resetCurrentPage',
    requestParams: 'updateGameList'
  }
};
