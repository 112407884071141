
























































































import { DataTableHeader } from 'vuetify';
import { ICopyStakeGameItemResponse } from '@/api/schema';

import { toTitleCase } from '@/helpers/formatString';
import { addThumbnailPrefix } from '@/helpers/copyStakeHelpers';

const MAX_TOP_GAMES_COUNT = 50;

export default {
  props: {
    rows: {
      type: Array,
      default: (): any => []
    },
    hideColumns: {
      type: Array,
      default: (): string[] => []
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    topGamesCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    tableHeaders(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        {
          text: 'Game Code',
          value: 'code'
        },
        {
          text: 'Game Name',
          value: 'name'
        },
        {
          text: `TOP(${this.topGamesCount}/${MAX_TOP_GAMES_COUNT})`,
          value: 'top'
        },
        {
          text: 'Game Provider',
          value: 'provider'
        },
        {
          text: 'Date Added (UTC)',
          value: 'whitelistedAt'
        },
        {
          text: 'Game images',
          value: 'images',
          width: 140
        }
      ];

      if (this.isEditable) {
        headers.push({
          text: 'Action',
          value: 'actions',
          align: 'center'
        });
      }

      return headers.filter(
        (column) => !this.hideColumns.includes(column.value)
      );
    },
    isDisabledToTopBtn(): boolean {
      return (
        this.topGamesCount + this.selectedNonTopGames.length >
        MAX_TOP_GAMES_COUNT
      );
    },
    selectedTopGames(): ICopyStakeGameItemResponse[] {
      return this.$attrs.value.filter(({ top }) => !!top) || [];
    },
    selectedNonTopGames(): ICopyStakeGameItemResponse[] {
      return this.$attrs.value.filter(({ top }) => !top) || [];
    }
  },
  methods: {
    toTitleCase,
    addThumbnailPrefix,
    isDisabledGameTopStatusSwitch(game: ICopyStakeGameItemResponse): boolean {
      return (
        this.$attrs.loading ||
        (this.topGamesCount >= MAX_TOP_GAMES_COUNT && !game.top)
      );
    },
    handlerOpenConfirmRemoveModal(): void {
      this.$emit('open-confirm-remove-modal');
    },
    handlerOpenConfirmRemoveAllModal(): void {
      this.$emit('open-confirm-remove-all-modal');
    },
    handlerAddGamesToTop(): void {
      this.$emit('change-games-top-status', this.selectedNonTopGames, true);
    },
    handlerRemoveGamesFromTop(): void {
      this.$emit('change-games-top-status', this.selectedTopGames, false);
    },
    handlerOpenEditGameImageDialog(
      payload: ICopyStakeGameItemResponse
    ): void {
      this.$emit('open-edit-game-image-dialog', payload);
    },
    handlerOpenGameImagePreviewDialog(imageUrl?: string): void {
      this.$emit('open-game-image-preview-dialog', imageUrl);
    },
    handlerChangeGameTopStatusSwitch(game: ICopyStakeGameItemResponse): void {
      this.$emit('change-games-top-status', [game], game.top);
    }
  }
};
