


















import BaseDialog from '@/components/BaseDialog.vue';
export default {
  components: {
    BaseDialog
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    closeModal(): void {
      this.$emit('input', false);
    },
    handleClickSubmit(): void {
      this.closeModal();
      this.$emit('submit');
    }
  }
};
