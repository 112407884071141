




























































import { PropType } from 'vue';

import BaseDialog from '@/components/BaseDialog.vue';
import ImageUploader from '@/components/ImageUploader.vue';

import { ValidationRules, ICopyStakeGameItemResponse } from '@/api/schema';
import * as vr from '@/helpers/validation';

const maxFileSize = 1024 * 400;
const imageThreeFourRequirements = `Image aspect ratio must be 3:4 (recommended dimensions is 810 x 1080 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;
const imageSixteenNineRequirements = `Image aspect ratio must be 16:9 (recommended dimensions is 1920 x 1080 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;

export default {
  name: 'CopyStakeWhitelistGameDialog',
  components: {
    BaseDialog,
    ImageUploader
  },
  props: {
    value: {
      type: Boolean,
      default: (): boolean => false
    },
    game: {
      type: Object as PropType<ICopyStakeGameItemResponse>,
      default: (): any => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      isFormValid: false,
      uploadImageThreeFourFile: null,
      uploadImageSixteenNineFile: null,
      imageThreeFourRequirements,
      imageSixteenNineRequirements,
      maxFileSize
    };
  },
  watch: {
    async value(): Promise<void> {
      await this.resetForm();
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    rules(): ValidationRules {
      return {
        imageThreeFour: [
          (v) => vr.fileMaxSize(v, maxFileSize, imageThreeFourRequirements)
        ],
        imageSixteenNine: [
          (v) => vr.fileMaxSize(v, maxFileSize, imageSixteenNineRequirements)
        ]
      };
    },
    isEdit(): boolean {
      return !!this.game?.id;
    },
    submitText(): string {
      return this.isEdit ? 'Update' : 'Create';
    },
    imageThreeFourUrl(): string {
      return this.game?.images?.THREE_FOUR ?? '';
    },
    imageSixteenNineUrl(): string {
      return this.game?.images?.SIXTEEN_NINE ?? '';
    }
  },
  methods: {
    async handlerOnSubmitForm(): Promise<void> {
      await this.$refs.form.validate();

      if (!this.isFormValid) return;

      this.$emit('submit', {
        uploadImageThreeFourFile: this.uploadImageThreeFourFile,
        uploadImageSixteenNineFile: this.uploadImageSixteenNineFile
      });
    },
    handlerOnUploadImageThreeFour(image: File): void {
      this.uploadImageThreeFourFile = image;
    },
    handlerOnUploadImageSixteenNine(image: File): void {
      this.uploadImageSixteenNineFile = image;
    },
    closeDialog(): void {
      this.showDialog = false;

      this.$emit('close');
    },
    async resetForm(): Promise<void> {
      await this.$refs.form?.reset();

      this.$refs.imageThreeFourUploader?.clearConfiguration();
      this.$refs.imageSixteenNineUploader?.clearConfiguration();
      this.uploadImageThreeFourFile = null;
      this.uploadImageSixteenNineFile = null;
    }
  }
};
