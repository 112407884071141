

























































import { IMenuFilterItem } from '@/api/schema';

export default {
  name: 'VMenuFilter',

  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: (): any => []
    },
    value: {
      type: Array,
      default: (): any => []
    },
    listHeight: {
      type: Number,
      default: 360
    }
  },

  data(): any {
    return {
      openMenu: false,
      selected: [...this.value]
    };
  },

  computed: {
    optionsList(): IMenuFilterItem[] {
      return this.options.map((option: IMenuFilterItem) => ({
        ...option,
        active: this.selected.some(({ value }) => value === option.value)
      }));
    },

    activeButton(): boolean {
      return !!this.selected.length;
    }
  },

  watch: {
    value(v: string[]): void {
      this.selected = [...v];
    }
  },

  methods: {
    select(item: IMenuFilterItem): void {
      if (item.active) {
        const i = this.selected.findIndex(
          ({ value }) => value === item.value
        );
        this.selected.splice(i, 1);
      } else {
        this.selected.push(item);
      }
    },

    handleApply(): void {
      this.$emit('input', [...this.selected]);
      this.openMenu = false;
    },

    handleClear(): void {
      this.$emit('input', []);
      this.selected = [];
      this.openMenu = false;
    }
  }
};
