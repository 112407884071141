









































import { PropType } from 'vue';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';

import BaseDialog from '@/components/BaseDialog.vue';
import CopyStakeWhitelistFilter from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistFilter.vue';
import CopyStakeWhitelistTable from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistTable.vue';
import CopyStakeWhitelistMixin from '@/views/CopyStake/CopyStakeWhitelist/CopyStakeWhitelistMixin';

const defaultOptions = {
  page: 1,
  itemsPerPage: 5
};

export default {
  name: 'CopyStakeWhitelistAddGameModal',
  mixins: [CopyStakeWhitelistMixin],
  components: {
    CopyStakeWhitelistTable,
    BaseDialog,
    CopyStakeWhitelistFilter
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    optionsGameType: {
      type: Array,
      default: (): any => []
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      options: { ...defaultOptions },
      whitelisted: false
    };
  },

  watch: {
    value(open: boolean): void {
      if (!open) this.resetSelectedItems();
    }
  },

  methods: {
    closeModal(): void {
      this.$emit('input', false);
    },
    addGames(): void {
      const countLabel = this.labelCountGames;

      this.setPermissionGames()
        .then(() => {
          this.resetFilter();
          this.$emit('update-game-list');
          this.closeModal();

          this.$toast.success(`${countLabel} were added successfully`);
        })
        .catch((err: AxiosError) => errorToastMessage(err));
    }
  }
};
