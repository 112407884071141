var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table-with-pagination',_vm._g(_vm._b({staticClass:"copy-stake-whitelist-table copy-stake-whitelist-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.rows,"checkbox-color":"primary","show-select":_vm.isEditable,"disable-sort":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var code = ref.item.code;
return [_c('div',{staticClass:"copy-stake-whitelist-table__game-code"},[_vm._v(_vm._s(code))])]}},{key:"item.provider",fn:function(ref){
var provider = ref.item.provider;
return [_vm._v(_vm._s(provider ? _vm.toTitleCase(provider) : '-'))]}},{key:"item.top",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":_vm.isDisabledGameTopStatusSwitch(item)},on:{"change":function($event){return _vm.handlerChangeGameTopStatusSwitch(item)}},model:{value:(item.top),callback:function ($$v) {_vm.$set(item, "top", $$v)},expression:"item.top"}})]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","disabled":!item.images.THREE_FOUR},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.handlerOpenGameImagePreviewDialog(item.images.THREE_FOUR)}}},[(item.images.THREE_FOUR)?_c('div',{staticClass:"copy-stake-whitelist-table__game-preview",style:({ backgroundImage: ("url('" + (_vm.addThumbnailPrefix(item.images.THREE_FOUR)) + "')") })}):_c('v-icon',[_vm._v("mdi-image")])],1),_c('v-btn',{attrs:{"icon":"","disabled":!item.images.SIXTEEN_NINE},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.handlerOpenGameImagePreviewDialog(item.images.SIXTEEN_NINE)}}},[(item.images.SIXTEEN_NINE)?_c('div',{staticClass:"copy-stake-whitelist-table__game-preview",style:({ backgroundImage: ("url('" + (_vm.addThumbnailPrefix(item.images.SIXTEEN_NINE)) + "')") })}):_c('v-icon',[_vm._v("mdi-image ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"copy-stake-whitelist-table__action copy-stake-whitelist-table__action--edit",attrs:{"icon":""},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.handlerOpenEditGameImageDialog(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}},{key:"select-dialog",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',[_c('v-col',{staticClass:"pa-1"},[(_vm.selectedNonTopGames.length)?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.isDisabledToTopBtn,"color":"primary","outlined":""},on:{"click":_vm.handlerAddGamesToTop}},[_vm._v("To TOP "+_vm._s(("" + (_vm.selectedTopGames.length ? '(' + _vm.selectedNonTopGames.length + ')' : ''))))]):_vm._e(),(_vm.selectedTopGames.length)?_c('v-btn',{attrs:{"color":"#50535A","outlined":""},on:{"click":_vm.handlerRemoveGamesFromTop}},[_c('v-icon',{staticClass:"mr-2 pa-0"},[_vm._v("mdi-delete-outline")]),_vm._v("Remove From TOP "+_vm._s(("" + (_vm.selectedNonTopGames.length ? '(' + _vm.selectedTopGames.length + ')' : ''))))],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"error"},on:{"click":_vm.handlerOpenConfirmRemoveModal}},[_c('v-icon',{staticClass:"mr-2 pa-0"},[_vm._v("mdi-delete-outline")]),_vm._v("Remove")],1),(_vm.serverItemsLength > 1)?_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":_vm.handlerOpenConfirmRemoveAllModal}},[_c('v-icon',{staticClass:"mr-2 pa-0"},[_vm._v("mdi-delete-outline")]),_vm._v("Remove All ("+_vm._s(_vm.serverItemsLength)+")")],1):_vm._e()],1)],1)],1)]},proxy:true}])},'v-data-table-with-pagination',Object.assign({}, _vm.$attrs, {serverItemsLength: _vm.serverItemsLength}),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }